import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient, private userService: UserService) {}

  setToken(token): void {
    localStorage.setItem('token', token);
  }

  isLoggedIn(): boolean {
    return !!localStorage.getItem('token'); //lo que hace es que verifica si existe un token y si existe envia tru y si no false, es la form abreviadade verificar con un if
  }

  signOut() {
    localStorage.removeItem('token');
    this.userService.updateUser('');
  }

  getToken() {
    return localStorage.getItem('token');
  }
}
