import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  readonly URL_API_USER = `${environment.urlAPI}/users`;

  private user = new BehaviorSubject<any>('');
  user$ = this.user.asObservable();

  constructor(public http: HttpClient) {}

  signIn(loginForm: FormGroup) {
    const { email, password } = loginForm.value;
    const data = { email, password };
    return this.http.post(this.URL_API_USER + '/auth/signin', data);
  }

  sendRecoveryMail(email) {
    console.log(email);
    return this.http.put(`${this.URL_API_USER}/recover-password/recover`, {
      email
    });
  }

  setNewPassword(email, hash, newPassword) {
    return this.http.put(`${this.URL_API_USER}/recover-password/hash`, {
      email,
      hash,
      newPassword
    });
  }

  signOut() {
    localStorage.removeItem('token');
    this.user.next(null);
  }

  // USER
  create(firstName, lastName, email, password, role) {
    return this.http.post(`${this.URL_API_USER}/`, {
      firstName,
      lastName,
      email,
      password,
      role
    });
  }

  update(userId, data) {
    return this.http.patch(`${this.URL_API_USER}/${userId}`, data);
  }

  getUser() {
    return this.http.get(`${this.URL_API_USER}/profile`);
  }
  getAll() {
    return this.http.get(this.URL_API_USER);
  }

  delete(id: string) {
    return this.http.delete(`${this.URL_API_USER + '/' + id}`);
  }

  updateUser(user: any) {
    this.user.next(user);
  }

  updateUserInfo() {
    this.http.get(this.URL_API_USER + '/profile').subscribe(res => {
      console.log(res);
      this.user.next(res);
    });
  }

  updateAccountDetails(userId, data) {
    return this.http.put(
      this.URL_API_USER + '/' + userId + '/account-details',
      data
    );
  }

  resetPassword(currentPassword, newPassword) {
    const data = { currentPassword, newPassword };
    return this.http.put(this.URL_API_USER + '/resetPassword', data);
  }
  //END USER
}
