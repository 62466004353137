import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <!-- <span class="created-by">Drechos reservados <b><a href="http://compugaming.net" target="_blank">Compugaming Store</a></b> 2019</span> -->
    <!-- <div class="socials"> -->
    <!--   <a href="#" target="_blank" class="ion ion-social-facebook"></a> -->
    <!--   <a href="#" target="_blank" class="ion ion-social-youtube"></a> -->
    <!-- </div> -->
  `,
})
export class FooterComponent {
}
